.MuiTableCell-head span {
  width: 100%;
  margin-bottom: 0.5em;
}
.MuiTableCell-head div {
  overflow: hidden;
  text-overflow: ellipsis;
}
.MuiTableCell-head svg {
  position: absolute;
  bottom: -18px;
  margin: 0;
}
.MuiTableCell-head div:hover {
  background: white;
  width: fit-content;
}
.MuiTableCell-head span:hover {
  display: inline-block;
}
.MuiTableCell-head:hover {
  z-index: 11;
}
